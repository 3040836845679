import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import App from './App';

// ReactDOM.render(
//     <App />,
//     document.getElementById('root')
// )

let root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);