import React from 'react';

//styles
import '../css/introduction.css'

const Introduction = () => {
    return ( 
        <div className="introduction">
           
                <div className=" intro-grid-1">
                    <div className="introduction">
    
                        <div className="introduction-normal text-center">                          
                            {/* <i> “A haiku is the perfect literary vehicle for bite-sized observations and contemplations. Limited to 17 syllables, there isn't much room for tiptoeing around your idea.”</i>
                            <br /> - Warby Parker  */}
                            <i>“This is your time. A new frontier into your being has arrived. Mixing an image and HAIQ words will create a modern masterpiece for all to see.”</i>
                        </div>
                    </div>
                </div>
            
        </div>
     );
}
 
export default Introduction;